/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withStyles } from '@material-ui/core';
import {
    string,
    object,
} from 'prop-types';

const styles = (theme) => ({
    fill: {
        fill: theme.palette.highlightColor || '#F5F5F5',
    },
});

const AddCircleIcon = ({
    stroke,
    classes,
}) => (
    <svg xmlns="http://www.w3.org/2000/svg" style={{ borderRadius: '50%' }} width="24" height="24" viewBox="0 0 24 24">
        <circle cx="12" cy="12" r="12" className={classes.fill} />
        <rect x="5.5" y="11.5" width="13" height="1.5" fill={stroke} />
        <rect x="11.5" y="5.5" width="1.5" height="13" fill={stroke} />
    </svg>
);

AddCircleIcon.propTypes = {
    stroke: string,
    classes: object.isRequired,
};

AddCircleIcon.defaultProps = {
    stroke: '#2F2F2F',
};

export default withStyles(styles)(AddCircleIcon);
