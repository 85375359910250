/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    func, arrayOf, object, shape, string, bool, array,
} from 'prop-types';
import List from '@material-ui/core/List';
import { useUIDSeed } from 'react-uid';
import { withRouter } from 'react-router-dom';
import ProductFacetViewV3 from './ProductFacetViewV3';
import useClickAway from './useClickAway';
import useSSRMediaQuery from '../../../../../helpers/hooks/useSSRMediaQuery';

const styles = (theme) => ({
    root: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(8, calc(12.5% - 14px))',
        gap: '16px',
        paddingBottom: '12px',
        [theme.breakpoints.down(1025)]: {
            display: 'block',
        },
    },
    isMobilePopDialog: {
        paddingTop: 0,
        paddingBottom: 0,
        '& :not(:first-child) $ListItem': {
            borderTop: '1px solid #cdcdcd',
            paddingTop: '5px',
        },
    },
    nested: {
        padding: '0px 0px 0px 2px',
        width: '100%',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: theme.palette.contrastText,
        },
        '& .MuiFormControlLabel-root': {
            alignItems: 'start',
            margin: '0 5px 10px 5px',
            [theme.breakpoints.down(481)]: {
                margin: '0 0 6px 5px',
            },
            [theme.breakpoints.down(1025)]: {
                alignItems: 'center',
            },
        },
        '& .MuiFormControlLabel-label': {
            fontSize: '0.95em',
            marginLeft: '1px',
        },
        [theme.breakpoints.between(1025, 1124)]: {
            width: '130px',
        },
        [theme.breakpoints.between(1125, 1225)]: {
            width: '140px',
        },
        [theme.breakpoints.between(1225, 1320)]: {
            width: '150px',
        },
        [theme.breakpoints.between(1320, 1420)]: {
            width: '153px',
        },
        [theme.breakpoints.up(1420)]: {
            width: '161px',
        },
    },
    flowerFacetItem: {
        minWidth: '200px',
        width: 'auto',
        [theme.breakpoints.down(1431)]: {
            minWidth: '14vw',
        },
    },
    facetsName: {
        fontWeight: '700',
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '1em',
        color: theme.palette.submit,
        '&:hover': {
            cursor: 'pointer',
        },
        [theme.breakpoints.up(1025)]: {
            textAlign: 'center',
        },
    },
    facetsNameFood: {
        width: '100%',
        fontWeight: '400',
        // fontFamily: 'LatoRegular, Arial, Verdana, sans-serif',
        fontSize: '14px',
        lineHeight: '1em',
        [theme.breakpoints.down(1025)]: {
            fontSize: '18px',
            fontWeight: '700',
        },
    },
    facetsValuesContainer: {
        paddingLeft: '4px',
    },
    facetsValues: {
        fontWeight: '400',
        fontFamily: 'inherit',
        fontStyle: 'normal',
        fontSize: '1rem',
        height: '30px',
        textAlign: 'left',
        lineHeight: 'normal',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
        [theme.breakpoints.up(1025)]: {
            fontSize: '14px',
        },
    },
    checkbox: {
        padding: '0px 11px 0px 9px',
    },
    checked: {
        padding: '0px 11px 0px 9px',
        '&$checked': {
            color: theme?.palette?.cms?.checkbox,
        },
    },
    ListItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 'auto',
        border: '1px solid',
        height: '40px',
        padding: '0 4px 0 10px',
        outline: 'none',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.contrastText,
        },
        [theme.breakpoints.down(1025)]: {
            border: 'none',
            justifyContent: 'space-between',
            height: '60px',
        },
        [theme.breakpoints.down(481)]: {
            '& svg': {
                width: '.8em',
            },
        },
    },
    flowerListItem: {
        [theme.breakpoints.up(1025)]: {
            padding: '0 10px',
            justifyContent: 'space-between',
        },
    },
    selectedListItem: {
        backgroundColor: theme.palette?.cms?.grey || '#f4f2f7',
    },
    rootFacetsValues: {
        padding: '10px 0 3px',
        [theme.breakpoints.down(1025)]: {
            padding: '0px',
            '& div.MuiFormControl-root': {
                position: 'relative',
            },
        },
    },
    rootFacetsValueOverflow: {
        overflowY: 'auto',
        padding: '14px 0',
        [theme.breakpoints.down(1025)]: {
            padding: '0',
            position: 'inherit',
            boxShadow: 'none',
            width: '100%',
            '& div.MuiFormControl-root': {
                position: 'relative',
            },
        },
    },
    clearFilters: {
        display: 'contents',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    clearFiltersCopy: {
        fontSize: '15px',
        paddingRight: '17px',
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    filterBy: {
        paddingLeft: '15px',
        fontSize: '20px',
    },
    facetNumber: {
        fontSize: '12px',
        paddingLeft: '2px',
    },
    listMore: {
        cursor: 'pointer',
        display: 'flex',
        padding: '2px 0px 5px 13px',
        alignItems: 'center',
        marginBottom: '15px',
        '& svg': {
            fontSize: '15px',
            margin: '0 5px 0 7px',
        },
        '& span': {
            fontSize: '16px',
        },
        [theme.breakpoints.down(1025)]: {
            '& svg': {
                margin: '0 9px 0 4px',
            },
        },
        [theme.breakpoints.down(600)]: {
            '& svg': {
                fontSize: '15px',
                margin: '0 12px 0 2px',
                boxShadow: '2px 2px 3px rgba(216, 216, 216, .9)',
            },
        },
    },
    facetsCheckbox: {
        padding: '3px 0 0 0',
        width: '20px',
        height: '20px',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    filterChecked: {
        borderRadius: '3px',
        width: '22px',
        height: '22px',
        boxShadow: 'inset 0 0 0 1px rgba(118,118,118,1), inset 0 -1px 0 rgba(118,118,118,1)',
        'input:hover ~ &': {
            backgroundColor: theme.palette.cms?.white || '#ffffff',
        },
    },
    mainNavBarFilterV2: {
        overflow: 'visible',
        marginBottom: '24px',
    },
    openCollapseBox: {
        boxShadow: '0px 2px 4px 0px #555',
        position: 'absolute',
        zIndex: '999',
        marginRight: '16px',
        width: 'min-content',
        maxWidth: '100%',
        overflow: 'auto',
        background: theme.palette.cms?.white || '#ffffff',
        top: '57px',
        [theme.breakpoints.down(1131)]: {
            marginRight: '7px',
        },
        [theme.breakpoints.down(1025)]: {
            padding: '0',
            position: 'relative',
            boxShadow: 'none',
            width: '100%',
            top: '0px',
        },
    },
    openFacetDropdown: {
        [theme.breakpoints.up(1025)]: {
            backgroundColor: theme.palette.highlightColor || '#F5F5F5',
        },
    },
    leftMenuFacetsBlock: {
        display: 'block',
        paddingBottom: '20px',
    },
    leftFacetItem: {
        width: '100%',
        '&:first-child': {
            justifyContent: 'space-between',
        },
    },
    filtersColumnsContainer: {
        [theme.breakpoints.up(1025)]: {
            display: 'flex',
            gap: '16px',
        },
    },
    filterColumns: {
        display: 'flex',
        flexDirection: 'column',
    },
});

const ProductFacetsV3 = ({
    productFacets,
    setFacetsDataInCollection,
    classes,
    isFoodBrand,
    isFacetFiltersCountEnabled,
    track,
    filterOn,
    openDisplayFacets,
    category,
    isPopFilterSlider,
    facetsOnLeftMenu,
    setCheckedOptions,
    checkedOptions,
    openFacetDropdown,
    setFacetLabel,
}) => {
    const seed = useUIDSeed();
    const device = useSSRMediaQuery({
        tablet: 1024, // this layout is visible till 1024
    });

    const [keyBasedChange, setKeyBasedChange] = useState(null);

    const { dialogRef, containerRef, handleOnClick } = useClickAway();

    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            id="nested-list-subheader"
            ref={containerRef}
            className={`${classes.root} ${isPopFilterSlider ? classes.isMobilePopDialog : ''} ${facetsOnLeftMenu ? classes.leftMenuFacetsBlock : ''}`}
        >
            {productFacets ? productFacets.map((facets, key) => (
                <div key={seed(facets)}>
                    <ProductFacetViewV3
                        classes={classes}
                        facetsView={facets}
                        setKeyBasedChange={setKeyBasedChange}
                        keyBasedChange={keyBasedChange}
                        facetKeyView={key}
                        category={category}
                        setFacetsDataInCollection={setFacetsDataInCollection}
                        checkedOptions={checkedOptions}
                        setCheckedOptions={setCheckedOptions}
                        isFoodBrand={isFoodBrand}
                        onScroll={() => {}}
                        isFacetFiltersCountEnabled={isFacetFiltersCountEnabled}
                        track={track}
                        filterOn={filterOn}
                        openDisplayFacets={openDisplayFacets}
                        facetsOnLeftMenu={facetsOnLeftMenu}
                        openFacetDropdown={openFacetDropdown}
                        setFacetLabel={setFacetLabel}
                        handleOnClick={handleOnClick}
                        dialogRef={dialogRef}
                        isDesktop={device === 'desktop'}
                    />
                </div>
            )) : null}
        </List>
    );
};

ProductFacetsV3.defaultProps = {
    track: () => {},
    filterOn: '',
};

ProductFacetsV3.propTypes = {
    classes: object.isRequired,
    productFacets: arrayOf(
        shape({
            name: string.isRequired,
            entries: arrayOf(
                shape({
                    name: string.isRequired,
                    value: string.isRequired,
                }),
            ).isRequired,
        }).isRequired,
    ).isRequired,
    setFacetsDataInCollection: func.isRequired,
    isFoodBrand: bool.isRequired,
    isFacetFiltersCountEnabled: bool.isRequired,
    track: func,
    filterOn: string,
    category: object.isRequired,
    isPopFilterSlider: bool,
    openDisplayFacets: bool,
    facetsOnLeftMenu: bool,
    setCheckedOptions: func.isRequired,
    checkedOptions: array.isRequired,
    openFacetDropdown: string.isRequired,
    setFacetLabel: func.isRequired,
};
ProductFacetsV3.defaultProps = {
    isPopFilterSlider: false,
    openDisplayFacets: false,
    facetsOnLeftMenu: false,
};
export default withRouter(withStyles(styles)(ProductFacetsV3));
