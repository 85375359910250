/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    bool,  func, string, number,
} from 'prop-types';
import Media from 'react-media';
import DesktopFilterDialogV3 from './DesktopFilterDialog/DesktopFilterDialogV3';

export const FilterDialog = ({
    setFilterOptions,
    isFacetFiltersEnabled,
    presentation_family,
    brand,
    ssrDeviceType,
    styleLeftSidebar,
    productCount,
    leftRail,
    filterOn,
}) => (
    <Media query={{ maxWidth: 1024 }} defaultMatches={ssrDeviceType === 'mobile'}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {(smallScreens) => (!smallScreens && (
            <DesktopFilterDialogV3
                setFilterOptions={setFilterOptions}
                isFacetFiltersEnabled={isFacetFiltersEnabled}
                brand={brand}
                presentationFamily={presentation_family}
                styleLeftSidebar={styleLeftSidebar}
                productCount={productCount}
                leftRail={leftRail}
                filterOn={filterOn}
            />
        )
        )}
    </Media>
);

FilterDialog.propTypes = {
    setFilterOptions: func.isRequired,
    brand: string.isRequired,
    presentation_family: string.isRequired,
    isFacetFiltersEnabled: bool.isRequired,
    ssrDeviceType: string,
    styleLeftSidebar: string,
    productCount: number,
    leftRail: bool,
    filterOn: string,
};
FilterDialog.defaultProps = {
    ssrDeviceType: '',
    styleLeftSidebar: '',
    productCount: 0,
    leftRail: false,
    filterOn: '',
};

export default FilterDialog;
