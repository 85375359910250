/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    arrayOf,
    bool,
    func,
    number,
    object,
    shape,
    string,
    array,
} from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Dialog, DialogTitle, useMediaQuery } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { useSelector } from 'react-redux';
import FilterIcon from '../../../../BrandTheme/Icons/FilterSvgIcon';
import ProductFacetsV3 from './ProductFacetsV3';
import FilterChipsUI from './FilterChipsUI';
import { getFeatureFlags } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import DesktopFilterDialogV3 from '../FilterDialog/DesktopFilterDialog/DesktopFilterDialogV3';
import CollectionProductCountUI from '../../CollectionProductCountUI';

const styles = (theme) => ({
    mainFilterContainerMobileV3: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '12px',
    },
    filterButton: {
        display: 'flex',
        alignItems: 'center',
        '&>span': {
            fontSize: '1rem',
            display: 'flex',
            alignItems: 'center',
            fontWeight: '600',
            fontStyle: 'normal',
            lineHeight: '16px',
            letterSpacing: '0.05em',
            position: 'relative',
            width: '188px',
            justifyContent: 'center',
            height: '40px',
            background: theme.palette.common?.white || '#ffffff',
            border: `1px solid ${theme.palette.upsellsTextColor || '#2F2F2F'}`,
            boxSizing: 'border-box',
            cursor: 'pointer',
            [theme.breakpoints.down(599)]: {
                width: '126px',
                minWidth: 'max-content',
                padding: '0px 2px',
            },
            [theme.breakpoints.down(380)]: {
                width: '110px',
            },
        },
        '& svg': {
            marginLeft: '10px',
            marginTop: '2px',
            height: '18px',
            width: '18px',
        },
        [theme.breakpoints.down(599)]: {
            marginRight: '0.5rem',
            marginBottom: '12px',
        },
        '@media screen and (max-width: 350px)': {
            marginRight: '0.2rem',
        },
    },
    mainDialog: {
        '& .MuiDialog-paperWidthSm': {
            width: '100%',
            minHeight: 'calc(100% - 64px)',
        },
        '& .MuiDialogContent-dividers': {
            borderTop: 0,
            borderBottom: 'none',
            padding: '0 15px 10px 15px',
            [theme.breakpoints.down(415)]: {
                padding: '0 12px 10px 12px',
            },
        },
        '& .MuiDialogTitle-root': {
            textAlign: 'center',
        },
        [theme.breakpoints.down(481)]: {
            '& > div > div': {
                marginLeft: '15px',
                marginRight: '15px',
            },
        },
    },
    closebutton: {
        '& span': {
            width: '170px',
            border: '1px solid',
            margin: '0 auto',
            display: 'block',
            textAlign: 'center',
            padding: '12px 0px',
            fontWeight: '600',
            fontSize: '15px',
            marginBottom: '25px',
            fontFamily: 'Garamond, Times, serif',
            [theme.breakpoints.down(481)]: {
                width: '150px',
                padding: '8px 0px',
                fontSize: '16px',
            },
        },
        [theme.breakpoints.down(481)]: {
            marginTop: '10px',
        },
    },
    closeX: {
        backgroundColor: 'transparent',
        border: 'none',
        minHeight: 'max-content',
        '& svg': {
            color: theme?.palette?.black || '#2F2F2F',
            width: '24px',
        },
    },
    dialogHeading: {
        display: 'flex',
        justifyContent: 'space-between',
        boxShadow: '0 0 4px 2px rgba(12, 12, 12, 0.15)',
        zIndex: '12',
        padding: '8px 20px 8px 23px',
        alignItems: 'center',
        '& .MuiDialogTitle-root': {
            alignSelf: 'center',
            padding: 0,
            [theme.breakpoints.down(481)]: {
                padding: '0',
                '& h2': {
                    fontSize: '1.25rem',
                    fontFamily: 'latobold, Arial, Verdana, sans-serif',
                    '& svg': {
                        position: 'relative',
                        top: '3px',
                    },
                },
            },
        },
        '& *': {
            textAlign: 'left',
        },
        [theme.breakpoints.down(481)]: {
            padding: '10px 8px 10px 16px',
        },
    },
    dialogFooter: {
        display: 'flex',
        padding: '12px 0',
        justifyContent: 'center',
        gap: '15px',
        minHeight: 'max-content',
        [theme.breakpoints.up(415)]: {
            gap: '22px',
        },
        [theme.breakpoints.down(380)]: {
            gap: '10px',
        },
        boxShadow: '0 0 4px #00000040',
        zIndex: '20',
    },
    footerBtn: {
        fontSize: '1rem',
        fontFamily: 'latobold, Arial, Verdana, sans-serif',
        textTransform: 'uppercase',
        lineHeight: '1em',
        padding: '8px 22px 9px',
        minHeight: 'max-content',
        [theme.breakpoints.down(320)]: {
            padding: '8px 10px 9px',
        },
    },
    footerBtnLight: {
        color: '#2F2F2F',
        backgroundColor: '#FFFFFF',
        border: '1px solid #2F2F2F',
    },
    footerBtnDark: {
        backgroundColor: '#2F2F2F',
        color: '#FFFFFF',
        border: 'none',
    },
    mobileFilterContainer: {
        [theme.breakpoints.down(599)]: {
            display: 'flex',
            flex: '1',
            marginLeft: '0.5rem',
        },
        '@media screen and (max-width: 350px)': {
            marginLeft: '0.2rem',
        },
    },
    totalResultsTablet: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '18px',
        marginLeft: '10px',
        minWidth: '80px',
    },
    totalResults: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '18px',
        minWidth: '80px',
        alignItems: 'center',
        display: 'flex',
    },
    flexBox: {
        display: 'flex',
    },
    makeCenter: {
        paddingBottom: '13px',
    },
});

const MobileProductFacetsV3 = ({
    isFoodBrand,
    classes,
    productFacetsBase,
    isFacetFiltersCountEnabled,
    category,
    trackEventAction,
    openDisplayFacets,
    setFacetsDataInCollection,
    svgIconData,
    presentation_family,
    setFilterDataInCategoryBuilder,
    setCheckedOptions,
    checkedOptions,
    resetChecked,
    openFacetDropdown,
    productCount,
    isFacetFiltersEnabled,
    filterOn,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const isMobile = useMediaQuery('(max-width: 600px)');
    const isTablet = useMediaQuery('(max-width: 1024px)');
    const toggleFilterDialog = () => {
        setIsModalOpen(!isModalOpen);
    };
    const featureFlags = useSelector(getFeatureFlags);

    const numberOfFilterParams = (checkedOptions?.length && !isFoodBrand) ? checkedOptions.length : 0;
    return (
        <>
            <div className={classes.mainFilterContainerMobileV3}>
                {/* Filter Button */}
                <div className={classes.filterButton}>
                    {isFacetFiltersEnabled && productFacetsBase?.length ?   (
                        <span data-testid="show-filterData"  className={numberOfFilterParams ? classes.selectedFilterButton : ''} role="presentation" onClick={() => toggleFilterDialog()}>
                            {svgIconData(classes, isModalOpen, numberOfFilterParams)}
                        </span>
                    ) : ''}
                    {isTablet && !isMobile && featureFlags['is-collection-product-count-enabled'] && <CollectionProductCountUI itemCount={productCount} styleClass={classes.totalResultsTablet} />}
                </div>
                {/* "Sort By" button */}
                {featureFlags['is-sort-and-filter-collections-enabled'] && filterOn !== 'Search' ?  (
                    <div className={classes.mobileFilterContainer}>
                        {/* Sort By options shown in a drawer */}
                        <DesktopFilterDialogV3
                            setFilterOptions={setFilterDataInCategoryBuilder}
                            brand={category?.brand}
                            presentationFamily={presentation_family}
                        />
                    </div>
                ) : ''}
            </div>

            <div className={classes.flexBox}>
                {(isMobile && featureFlags['is-collection-product-count-enabled']) && <CollectionProductCountUI itemCount={productCount} styleClass={`${classes.totalResults} ${classes.makeCenter}`} />}
                {/* Pill shaped labels that show applied filters */}
                <FilterChipsUI
                    isInModal={false}
                    productFacetsBase={productFacetsBase}
                    checkedOptions={checkedOptions}
                    setCheckedOptions={setCheckedOptions}
                    setFacetsDataInCollection={setFacetsDataInCollection}
                    resetChecked={resetChecked}
                />
            </div>

            {isModalOpen
            && (
                <Dialog
                    open={isModalOpen}
                    maxWidth="sm"
                    className={classes.mainDialog}
                    PaperProps={{ square: true }}
                    in={isModalOpen}
                >
                    <div className={classes.dialogHeading}>
                        <DialogTitle className={classes.center}id="customized-dialog-title">
                            Filter <FilterIcon />
                        </DialogTitle>
                        <button type="button" aria-label="close" className={classes.closeX} onClick={toggleFilterDialog}><CloseIcon /></button>
                    </div>

                    <FilterChipsUI
                        isInModal
                        productFacetsBase={productFacetsBase}
                        checkedOptions={checkedOptions}
                        setCheckedOptions={setCheckedOptions}
                        setFacetsDataInCollection={setFacetsDataInCollection}
                        openFacetDropdown={openFacetDropdown}
                        resetChecked={resetChecked}
                    />

                    <MuiDialogContent dividers>
                        <Typography gutterBottom>
                            <div className={classes.contentpassport}>
                                {productFacetsBase?.length > 0 && (
                                    <ProductFacetsV3
                                        category={category}
                                        productFacets={productFacetsBase}
                                        setFacetsDataInCollection={setFacetsDataInCollection}
                                        isFoodBrand={isFoodBrand}
                                        isFacetFiltersCountEnabled={isFacetFiltersCountEnabled}
                                        track={trackEventAction}
                                        isPopFilterSlider
                                        filterOn="Search"
                                        openDisplayFacets={openDisplayFacets}
                                        setCheckedOptions={setCheckedOptions}
                                        checkedOptions={checkedOptions}
                                        openFacetDropdown={openFacetDropdown}

                                    />
                                )}
                            </div>
                        </Typography>
                    </MuiDialogContent>
                    <div className={classes.dialogFooter}>
                        <button type="button" aria-label="close" className={`${classes.footerBtn} ${classes.footerBtnLight}`} onClick={toggleFilterDialog}>
                            <span>Close</span>
                        </button>
                        <button type="button" aria-label="close" className={`${classes.footerBtn} ${classes.footerBtnLight}`} onClick={resetChecked}>
                            <span>Clear</span>
                        </button>
                        <button type="button" aria-label="close" className={`${classes.footerBtn} ${classes.footerBtnDark}`} onClick={toggleFilterDialog}>
                            <span>Apply</span>
                        </button>
                    </div>
                </Dialog>
            )}
        </>
    );
};

MobileProductFacetsV3.propTypes = {
    classes: object.isRequired,
    isFoodBrand: bool.isRequired,
    productFacetsBase: arrayOf(shape({
        name: string,
        entries: arrayOf(shape({
            count: number,
            from: number,
            name: string,
            value: string,
        })),
    })),
    isFacetFiltersCountEnabled: bool.isRequired,
    category: shape({
        id: string,
        name: string,
        productFacets: arrayOf(shape({
            name: string,
            entries: arrayOf(shape({
                count: number,
                from: number,
                name: string,
                value: string,
            })),
        })),
    }),
    trackEventAction: func,
    svgIconData: func.isRequired,
    setFacetsDataInCollection: func.isRequired,
    presentation_family: string.isRequired,
    setFilterDataInCategoryBuilder: func.isRequired,
    openDisplayFacets: bool,
    setCheckedOptions: func.isRequired,
    checkedOptions: array.isRequired,
    resetChecked: func.isRequired,
    openFacetDropdown: string.isRequired,
    productCount: number,
    isFacetFiltersEnabled: bool,
    filterOn: string,
};

MobileProductFacetsV3.defaultProps = {
    productFacetsBase: [],
    category: {},
    trackEventAction: () => {},
    openDisplayFacets: false,
    productCount: 0,
    isFacetFiltersEnabled: false,
    filterOn: 'Category',
};

const enhance = compose(
    withRouter,
    withStyles(styles),
);

export default enhance(MobileProductFacetsV3);
