/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    useEffect, useState, useCallback, useRef,
} from 'react';

export default function useClickAway() {
    const [open, setOpen] = useState(false);
    const dialogRef = useRef(null);
    const containerRef = useRef(null);

    function handleOnClick(modelOpen) {
        setOpen(modelOpen);
    }

    const getPositioningStyle = useCallback(
        (element) => {
            // we are getting the width of the dropDown model when  we open it
            const { width, left: elementLeft } = element.getBoundingClientRect();

            // we are getting the main container element
            const { left: rootLeft, width: rootWidth } = containerRef?.current.getBoundingClientRect();

            // we are getting the difference between  container to model on the left side

            const diff = elementLeft - rootLeft;

            // we are calculating the total width of the container
            const containerWidth =  diff + width;

            // we are getting actualWidth of the model
            const actualWidth = containerWidth - rootWidth;

            // Calculate element positioning
            const leftMargin =  diff - actualWidth;
            let  left = diff;
            if (containerWidth > rootWidth && leftMargin >= 0) {
                left = leftMargin;
            }
            return {
                left: `${Math.round(left)}px`,
            };
        },
    );

    const setPositioningStyles = useCallback(() => {
        const element = dialogRef?.current;
        if (!element) {
            return;
        }

        const positioning = getPositioningStyle(element);

        if (positioning.left !== null) {
            element.style.left = positioning.left;
        }
    }, [getPositioningStyle]);

    const handleResize = () => {
        if (open && document && typeof document !== 'undefined') {
            setPositioningStyles();
        }
    };
    useEffect(() => {
        window.addEventListener('resize', handleResize());
        return () => {
            window.removeEventListener('resize', handleResize());
        };
    }, [dialogRef, open, containerRef, setPositioningStyles]);
    return { dialogRef, containerRef, handleOnClick };
}
