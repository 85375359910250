/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    string,
} from 'prop-types';

const CloseCircleIcon = ({
    stroke,
}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <circle cx="10" cy="10" r="9.5" fill="none" stroke={stroke} strokeWidth="1" />
        <rect x="6.7" y="9.7" width="6.6" height="0.6" transform="translate(-4.142 10) rotate(-45)" stroke={stroke} strokeWidth="0.6" />
        <rect x="9.559" y="6.841" width="0.6" height="6.6" transform="translate(-4.283 9.941) rotate(-45)" stroke={stroke} strokeWidth="0.6" />
    </svg>
);

CloseCircleIcon.propTypes = {
    stroke: string,
};

CloseCircleIcon.defaultProps = {
    stroke: '#2F2F2F',
};

export default CloseCircleIcon;
