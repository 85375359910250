/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { createRef, PureComponent } from 'react';
import {
    object, shape, string, func, array, bool, number,
} from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import Media from 'react-media';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router';
import ReactHtmlParser from 'react-html-parser';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import { connect } from 'react-redux';
import AddCircleIcon from '../../../../BrandTheme/Icons/AddCircleIcon';
import MinusCircleIcon from '../../../../BrandTheme/Icons/MinusCircleIcon';
import CheckboxCheckedIcon from '../../../../BrandTheme/Icons/CheckboxCheckedIcon';
import CheckboxUncheckedIcon from '../../../../BrandTheme/Icons/CheckboxUncheckedIcon';
import { getActiveABTests } from '../../../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';
import { getFeatureFlag } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getSSRDeviceType } from '../../../../../../state/ducks/App/App-Selectors';
import noop from '../../../../../helpers/noop';
// this component controls each section of the mobile filters, e.g. PriceRange, Contents, Occasions are all examples of instances of this component.

class ProductFacetViewV3 extends PureComponent {
    constructor(props) {
        super(props);
        const {
            facetsView,
            isDesktop,
        } = this.props;
        this.state = {
            open: isDesktop ? false : facetsView?.displayOpen,
            selectedFilterCount: 0,
            countList: 3,
            isCollapsed: facetsView.entries.length > 4,
        };
        this.containerRef = createRef();
        this.collapse = createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount = () => {
        const {
            isFoodBrand, isDesktop, openFacetDropdown, facetsView, checkedOptions,
        } = this.props;
        const filterFacetsName = facetsView ? facetsView.name : null;
        const dropdownOpen = openFacetDropdown === filterFacetsName;
        if (dropdownOpen && !isDesktop) {
            this.setState({ open: true });
        }
        if (
            facetsView?.entries.length
            && checkedOptions.length && !isDesktop
        ) {
            checkedOptions.forEach((facet) => facetsView.entries.forEach((val, index) => {
                if (val.value === facet) {
                    this.setState({ open: true });
                    if (index >= 4) {
                        this.handleViewMore(facetsView.entries.length);
                    }
                }
            }));
        }
        if (!isFoodBrand) {
            this.countSelectedFilter();
        }
        if (typeof document !== 'undefined' && isDesktop) {
            document.addEventListener('click', this.handleClickOutside);
        }
    }

    componentWillUnmount() {
        const { isDesktop } = this.props;
        if (typeof document !== 'undefined' && isDesktop) {
            document.removeEventListener('click', this.handleClickOutside);
        }
    }

    handleClickOutside = (event) => {
        const { open } = this.state;
        if (this.collapse && this.collapse?.current && open && !this.collapse.current?.contains(event.target) && !event?.target?.getAttribute?.('data-identifier')) {
            this.setState({
                open: false,
                isCollapsed: true,
                countList: 3,
            });
        }
    }

    handleFacetDropdownClick = (e, keydata, isOpen) => {
        const { open } = this.state;
        const {
            setKeyBasedChange, keyBasedChange, handleOnClick, isDesktop,
        } = this.props;
        if (isDesktop) handleOnClick(!open, e.currentTarget);
        const newFacetClicked = keydata !== keyBasedChange;
        if (keyBasedChange === null) {
            this.setState({ open: !isOpen });
        } else if (newFacetClicked) {
            this.setState({ open: !isOpen });
        } else if (keyBasedChange === keydata || !isDesktop) {
            this.setState({ open: !open });
        }
        setKeyBasedChange(keydata);
    }

    handleViewMore =(count) => {
        const { isCollapsed } = this.state;
        const { onScroll, isFoodBrand } = this.props;
        this.setState({ isCollapsed: !isCollapsed, countList: !isCollapsed ? 3 : count });
        if (isFoodBrand) {
            setTimeout(() => onScroll(), 100);
        }
    }

    labelContent =(filterOption, classes, isFoodBrand, isFacetFiltersCountEnabled) => (
        <div className={classes.facetsValuesContainer}>
            <Typography
                component="span"
                className={classes.facetsValues}
                color="textPrimary"
            >
                {ReactHtmlParser(filterOption?.name)}
            </Typography>
            {isFacetFiltersCountEnabled && (
                <Typography
                    className={classes.facetNumber}
                    component="span"
                    color="textPrimary"
                >
                    ({filterOption?.count})
                </Typography>
            )}
        </div>
    )

    loadListItem =(filterOption, checkedOptions, classes, isFoodBrand, isFacetFiltersCountEnabled, facetLabel = '') => {
        let isChecked = false;
        if (checkedOptions?.length) {
            isChecked = checkedOptions.includes(filterOption?.value);
        }
        return (
            <FormControl key={filterOption.value} className={classes.nested}>
                <FormGroup>
                    <FormControlLabel
                        control={(
                            <Media query={{ maxWidth: 1024 }}>
                                {(smallScreens) => (
                                    smallScreens ?  (
                                        <Checkbox
                                            color="primary"
                                            disableRipple
                                            name="facetsViewCheckbox"
                                            value={filterOption.value}
                                            checked={isChecked}
                                            data-testid={filterOption.value}
                                            icon={<CheckboxUncheckedIcon />}
                                            checkedIcon={<CheckboxCheckedIcon />}
                                            classes={{ root: classes.facetsCheckbox }}
                                            onChange={(e) => this.handleCheckboxClick(e, filterOption, facetLabel)}
                                        />
                                    ) : (
                                        <Checkbox
                                            color="primary"
                                            disableRipple
                                            name="facetsViewCheckbox"
                                            value={filterOption.value}
                                            checked={isChecked}
                                            data-testid={filterOption.value}
                                            icon={<CheckboxUncheckedIcon size={14} />}
                                            checkedIcon={<CheckboxCheckedIcon size="sm" />}
                                            classes={{ root: classes.facetsCheckbox }}
                                            onChange={(e) => this.handleCheckboxClick(e, filterOption, facetLabel)}
                                        />
                                    ))}
                            </Media>

                        )}
                        label={this.labelContent(filterOption, classes, isFoodBrand, isFacetFiltersCountEnabled)}
                    />
                </FormGroup>
            </FormControl>
        );
    }

    displayFiltersAsColumns = (filterOptionsColumn, checkedOptions, classes, isFoodBrand, isFacetFiltersCountEnabled, facetLabel = '') => (
        <div className={classes.filterColumn}>
            {filterOptionsColumn.map((filterOption) => this.loadListItem(filterOption, checkedOptions, classes, isFoodBrand, isFacetFiltersCountEnabled, facetLabel))}
        </div>
    );

    handleCheckboxClick = (e, data = null) => {
        const {
            checkedOptions,
            setCheckedOptions,
            track,
            category,
            isAggregatorFacetErrorResolver,
            isDesktop,
        } = this.props;
        const value = e.target.value;
        let checkedArr = [];

        if (checkedOptions && value) {
            checkedArr = [...checkedOptions];
            if (checkedArr.some((check) => check === value)) {
                checkedArr = checkedArr.filter((check) => check !== value);
            } else {
                checkedArr.push(value);
            }
            setCheckedOptions(checkedArr);
        }

        let delimiter = '&';
        if (isAggregatorFacetErrorResolver) {
            delimiter = '&facet=';
        }
        const urlSelectedParams = checkedArr.join(delimiter);
        // Fire facet interaction event
        if (category && checkedArr.some((check) => check === value) && data) {
            const { id, name } = category;
            const trackData = {
                eventCategory: 'Collection Page',
                eventAction: `Filter Interaction - V3 - ${name}`,
                eventLabel: data.value,
                category_name: name,
                category_id: id,
                filters_product_count: data.count,
                filters_page_url: urlSelectedParams,
                eventName: 'apply_filter',
                filter_name: data?.name || '',
            };
            track(trackData);
        }
        if (isDesktop) {
            this.setState({ open: false });
        }
    };

    groupFiltersInColumns = (facetsView) => {
        if (facetsView) {
            const filterOptionsGroupedForColumns = [];
            const filtersPerColumn = facetsView.entries.length > 36 ? 8 : 6;
            let currentColumn = [];
            let columnLimit = filtersPerColumn; // e.g. 6 for first column, 12 for second
            facetsView.entries.forEach((filterOption, index, arr) => {
                currentColumn.push(filterOption);
                if (index === columnLimit - 1 || index === arr.length - 1) {
                    columnLimit += filtersPerColumn;
                    filterOptionsGroupedForColumns.push(currentColumn);
                    currentColumn = [];
                }
            });
            return filterOptionsGroupedForColumns;
        }
        return null;
    }

    countSelectedFilter = () => {
        const {
            facetsView, checkedOptions,
        } = this.props;
        let selectCount = 0;

        checkedOptions?.map((checkedItem) => {
            selectCount = facetsView.entries.reduce((countValue, item) => countValue + (item.value === checkedItem ? 1 : 0), selectCount);
        });

        this.setState({
            selectedFilterCount: selectCount,
        });
    }

    getFacetTitleStyles = (classes, isFoodBrand, open) => {
        let styles = isFoodBrand ? classes.rootListItemV3 : `${classes.rootListItemV3} ${classes.flowerFacetItem}`;
        if (open) {
            styles += ` ${classes.openFacetDropdown}`;
        }
        return styles;
    }

    facetTitle = ({
        facetKeyView, filterFacetsName, isFoodBrand, classes, selectedFilterCount, isDesktop, facetsView, expandButton, open,
    }) => (
        <div
            onClick={(e) => this.handleFacetDropdownClick(e, facetKeyView, open)}
            onKeyDown={noop()}
            className={`${isFoodBrand ? classes.ListItem : `${classes.ListItem} ${classes.flowerListItem}`} ${selectedFilterCount && !isFoodBrand && isDesktop ? classes.selectedListItem : ''}`}
            // styles={{ selected: classes.active }}
            data-testid={facetsView.name}
            data-identifier="facets-label"
            role="button"
            tabIndex={0}
        >
            <Typography
                component="span"
                data-identifier="facets-label"
                className={`${classes.facetsName} ${isFoodBrand ? classes.facetsNameFood : ''}`}
                color="textPrimary"
            >
                {filterFacetsName}{!isFoodBrand && isDesktop && selectedFilterCount ? ` (${selectedFilterCount})` : ''}
            </Typography>
            {expandButton}
        </div>
    )

    render() {
        const {
            facetsView,
            classes,
            isFoodBrand,
            openDisplayFacets,
            checkedOptions,
            isFacetFiltersCountEnabled,
            history,
            activeABTests,
            facetKeyView,
            keyBasedChange,
            facetsOnLeftMenu,
            dialogRef,
            isDesktop,
            ssrDeviceType,
        } = this.props;
        const {
            open,
            selectedFilterCount,
            isCollapsed,
            countList,
        } = this.state;
        const filterOptionsGroupedForColumns = this.groupFiltersInColumns(facetsView);

        const filterFacetsName = facetsView ? facetsView.name : null;
        const filterOptionsLength = facetsView ? facetsView.entries.length : null;
        const facetName = filterFacetsName === 'Recipient Type' || filterFacetsName === 'Occasion';
        const collectionUrl = history?.location?.pathname === '/all-flower-arrangements' || history?.location?.pathname === '/plants';
        const facetsABTest = activeABTests?.show_filter_facets === 'Variant' && facetName;
        const openIsDisplayed = openDisplayFacets ? open : (keyBasedChange === facetKeyView) && open;
        const showFilterAndFacets = filterFacetsName !== 'OfferPrice_USD' && filterFacetsName !== 'ParentCatalogGroup' && facetsView?.entries?.length > 0;
        const expandButton = openIsDisplayed ? <ExpandLess data-testid="expand-more-icon" data-identifier="facets-label" /> : <ExpandMore data-testid="expand-less-icon" data-identifier="facets-label" />;
        const expandViewMore = (isCollapsed ? <><AddCircleIcon /><span>More</span></> : <> <MinusCircleIcon data-testid="remove-icon" /> <span>Less</span></>);
        return (
            <>
                {showFilterAndFacets && (!collectionUrl || (collectionUrl && (facetsABTest || !facetName)))
                    && (
                        <div className={this.getFacetTitleStyles(classes, isFoodBrand, (open && keyBasedChange === facetKeyView))} ref={this.containerRef}>
                            <Media query={{ maxWidth: 1024 }}>
                                {/* First 3 facets should be open by default on mobile */}
                                {(smallScreens) => (
                                    smallScreens ? (
                                        this.facetTitle({
                                            facetKeyView, filterFacetsName, isFoodBrand, classes, selectedFilterCount, isDesktop, facetsView, expandButton, open,
                                        })
                                    ) : (
                                        this.facetTitle({
                                            facetKeyView, filterFacetsName, isFoodBrand, classes, selectedFilterCount, isDesktop, facetsView, expandButton, open: false,
                                        })
                                    )
                                )}
                            </Media>
                            <div ref={open ? dialogRef : ''}  className={!facetsOnLeftMenu ? classes.openCollapseBox : ''} elevation={4}>
                                <Media defaultMatches={ssrDeviceType !== 'desktop'}  query={{ maxWidth: 1024 }}>
                                    {(smallScreens) => (
                                        smallScreens ? (
                                            <Collapse in={open} timeout="auto" unmountOnExit>
                                                <div component="div" disablePadding className={filterOptionsLength > 9 ? classes.rootFacetsValueOverflow : classes.rootFacetsValues}>
                                                    {(facetsView && checkedOptions) && facetsView.entries.map((filterOption, index) => (
                                                        countList >= index && this.loadListItem(filterOption, checkedOptions, classes, isFoodBrand, isFacetFiltersCountEnabled, filterFacetsName)
                                                    ),
                                                    )}
                                                    {(filterOptionsLength > 4) && (
                                                        <div
                                                            role="presentation"
                                                            className={classes.listMore}
                                                            data-testid="listMore"
                                                            onClick={() => this.handleViewMore(facetsView?.entries?.length)}
                                                        >
                                                            {expandViewMore}
                                                        </div>
                                                    )}
                                                </div>
                                            </Collapse>
                                        ) : (
                                            <Collapse in={keyBasedChange === facetKeyView && open} timeout="auto" unmountOnExit>
                                                <div ref={this.collapse} component="div" disablePadding className={`${classes.rootFacetsValues} ${classes.parent} ${classes.filtersColumnsContainer}`}>
                                                    {(filterOptionsGroupedForColumns && checkedOptions)
                                                        ? filterOptionsGroupedForColumns.map((filterOptionsColumn) => this.displayFiltersAsColumns(filterOptionsColumn, checkedOptions, classes, isFoodBrand, isFacetFiltersCountEnabled, filterFacetsName))
                                                        : null}
                                                </div>
                                            </Collapse>
                                        )
                                    )}
                                </Media>
                            </div>
                        </div>
                    )}
            </>
        );
    }
}
ProductFacetViewV3.defaultProps = {
    track: () => {},
    activeABTests: {},
    openDisplayFacets: false,
    isAggregatorFacetErrorResolver: false,
    onScroll: () => {},
    handleOnClick: () => {},
    dialogRef: {},
    isDesktop: false,
    ssrDeviceType: '',
};
ProductFacetViewV3.propTypes = {
    classes: object.isRequired,
    history: object.isRequired,
    facetsView: shape({
        name: string.isRequired,
    }).isRequired,
    checkedOptions: array.isRequired,
    setCheckedOptions: func.isRequired,
    isFoodBrand: bool.isRequired,
    isFacetFiltersCountEnabled: bool.isRequired,
    track: func,
    category: object.isRequired,
    activeABTests: shape({
        show_filter_facets: string,
    }),
    openDisplayFacets: bool,
    setKeyBasedChange: func.isRequired,
    keyBasedChange: number.isRequired,
    facetKeyView: number.isRequired,
    facetsOnLeftMenu: bool.isRequired,
    openFacetDropdown: string.isRequired,
    onScroll: func,
    handleOnClick: func,
    dialogRef: object,
    isDesktop: bool,
    isAggregatorFacetErrorResolver: bool,
    ssrDeviceType: string,
};

const mapStateToProps = (state) => ({
    activeABTests: getActiveABTests(state),
    isAggregatorFacetErrorResolver: getFeatureFlag('is-facet-throwing-error-resolver-enabled')(state),
    ssrDeviceType: getSSRDeviceType(state),
});

export default withRouter(connect(mapStateToProps)(ProductFacetViewV3));
