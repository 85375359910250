/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    string,
    number,
} from 'prop-types';

/*
*  params
*    [size] - can be adjusted to any pixel size (stroke will not scale)
*/

const CheckboxUncheckedIcon = ({
    stroke,
    size,
}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={size + 1} height={size + 1} viewBox={`0 0 ${size + 1} ${size + 1}`}>
        <rect
            x="0.5"
            y="0.5"
            width={size}
            height={size}
            fill="#fff"
            stroke={stroke}
        />
    </svg>
);

CheckboxUncheckedIcon.propTypes = {
    stroke: string,
    size: number,
};

CheckboxUncheckedIcon.defaultProps = {
    stroke: '#2F2F2F',
    size: 20,
};

export default CheckboxUncheckedIcon;
