/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withStyles } from '@material-ui/core';
import {
    string, object,
} from 'prop-types';

// default size of ~20px
// pass 'sm' to size param for size of 14px

const styles = (theme) => ({
    fill: {
        fill: theme.palette.highlightColor || '#F5F5F5',
    },
});

const CheckboxCheckedIcon = ({
    stroke,
    classes,
    size,
}) => {
    let sizeInPx = 20;
    if (size === 'sm') {
        sizeInPx = 14;
    }
    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width={sizeInPx + 1} height={sizeInPx + 1} viewBox={`0 0 ${sizeInPx + 1} ${sizeInPx + 1}`}>
            <rect className={classes.fill} x="0.5" y="0.5" width={sizeInPx} height={sizeInPx} stroke={stroke} strokeMiterlimit="10" />
            <path
                d={(size === 'sm') ? 'M3.79999 7L6.96997 10L10.97 4' : 'M5.4,10.9l3.2,3.6,7-8'}
                fill="none"
                stroke={stroke}
                strokeLinecap="round"
                strokeWidth="2"
            />
        </svg>
    );
};

CheckboxCheckedIcon.propTypes = {
    stroke: string,
    classes: object.isRequired,
    size: string,
};

CheckboxCheckedIcon.defaultProps = {
    stroke: '#2F2F2F',
    size: '',
};

export default withStyles(styles)(CheckboxCheckedIcon);
