/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    string,
} from 'prop-types';

const FilterIcon = ({
    fillColor,
}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path fill={fillColor} d="M9,5.5A1.5,1.5,0,1,1,7.5,4,1.5,1.5,0,0,1,9,5.5ZM7.5,8A2.5,2.5,0,0,0,9.95,6H18a.5.5,0,0,0,0-1H9.95a2.5,2.5,0,0,0-4.9,0H2A.5.5,0,0,0,2,6H5.05A2.5,2.5,0,0,0,7.5,8ZM11,14.5A1.5,1.5,0,1,0,12.5,13,1.5,1.5,0,0,0,11,14.5ZM12.5,12a2.5,2.5,0,0,1,2.45,2H18a.5.5,0,0,1,0,1H14.95a2.5,2.5,0,0,1-4.9,0H2a.5.5,0,0,1,0-1h8.05A2.5,2.5,0,0,1,12.5,12Z" />
    </svg>
);

FilterIcon.propTypes = {
    fillColor: string,
};

FilterIcon.defaultProps = {
    fillColor: '#2F2F2F',
};

export default FilterIcon;
