/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    bool,
    node, number, object, string,
} from 'prop-types';

const useStyles = makeStyles(() => ({
    totalResults: ({ inline }) => ({
        fontStyle: 'normal',
        fontWeight: 400,
        display: inline ? 'inline-block' : 'relative',
        marginRight: inline ? '15px' : '0px',
    }),
}));

const CollectionProductCountUI = ({
    itemCount, style, styleClass, spacer, inline,
}) => {
    const classes = useStyles({ style, inline });

    if (itemCount > 0) {
        return (
            <>
                {spacer}
                <div className={`${classes.totalResults} ${styleClass}`}>{itemCount} Result{itemCount === 1 ? '' : 's'}</div>
            </>
        );
    }
    return <></>;
};

CollectionProductCountUI.propTypes = {
    itemCount: number,
    style: object,
    styleClass: string,
    spacer: node,
    inline: bool,
};

CollectionProductCountUI.defaultProps = {
    itemCount: 0,
    style: {},
    styleClass: '',
    spacer: <></>,
    inline: false,
};

export default CollectionProductCountUI;
