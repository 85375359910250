/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useRef, useState } from 'react';
import {
    arrayOf,
    bool,
    func,
    number,
    object,
    shape,
    string,
} from 'prop-types';
import Media from 'react-media';
import { withRouter, useHistory } from 'react-router-dom';
import { compose } from 'recompose';
import { connect, useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import FilterIcon from '../../../../BrandTheme/Icons/FilterSvgIcon';
import MobileProductFacetsV3 from './MobileProductFacetsV3';
import { getFeatureFlag, getFeatureFlags } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import useCheckedFacets from '../../../../../helpers/hooks/useCheckedFacets';
import { FilterDialog } from '../FilterDialog/FilterDialog';
import useSSRMediaQuery from '../../../../../helpers/hooks/useSSRMediaQuery';

const MobileDesktopFacetsV3 = ({
    isFoodBrand,
    productFacetsBase,
    isFacetFiltersCountEnabled,
    filterFlag,
    category,
    trackEventAction,
    categoryData,
    abCategorySortAndFilter,
    isFacetFiltersEnabled,
    featureFlags,
    setFacetsDataInCollection,
    setFilterDataInCategoryBuilder,
    presentation_family,
    openFacetDropdown,
    productCount,
    filterOn,
}) => {
    const history = useHistory();
    const checkedFacetsData = useCheckedFacets();
    const [checkedOptions, setCheckedOptions] = useState(checkedFacetsData);
    const searchValue = unescape(history.location?.search);
    useEffect(() => {
        // if there is no search value, we dont need to do anything.
        if (searchValue !== '') {
            setCheckedOptions(checkedFacetsData);
            setFacetsDataInCollection(checkedFacetsData);
        }
    }, [searchValue]);

    const device = useSSRMediaQuery();
    const resetChecked = () => {
        setCheckedOptions([]);
        setFacetsDataInCollection([]);
    };
    const isAggregatorFacetErrorResolver = useSelector(getFeatureFlag('is-facet-throwing-error-resolver-enabled'));
    const timerId = useRef(null);

    const yieldToMain = () => {
        // Use scheduler.yield if it exists:
        if (typeof window !== 'undefined' && 'scheduler' in window && 'yield' in window.scheduler) {
            return window.scheduler.yield();
        }

        // Fall back to setTimeout:
        return new Promise((resolve) => setTimeout(resolve, 0));
    };

    const historyReplace = async (searchParam) => {
        history.replace({
            search: searchParam,
            state: checkedOptions,
        });
        await yieldToMain();
    };

    useEffect(() => {
        let delimiter = '&';
        if (isAggregatorFacetErrorResolver) {
            delimiter = '&facet=';
        }
        const urlSelectedParams = checkedOptions.join(delimiter);
        clearTimeout(timerId.current);
        timerId.current = setTimeout(() => { // delay the history for two ms
            let searchParam = '';
            if (urlSelectedParams) {
                searchParam = `facet=${urlSelectedParams}`;
            } else if (searchValue && !searchValue.includes('facet')) {
                searchParam = searchValue;
            }

            historyReplace(searchParam); // for scheduler yield
        }, 200);
    }, [checkedOptions?.length]);

    // filter sorting dialog
    const filterDialogData = () => category !== null && abCategorySortAndFilter !== 'hide' && (
        <FilterDialog
            cmsFilterFlag={categoryData?.content?.entries?.[0]?.show_filters}
            isFacetFiltersEnabled={isFacetFiltersEnabled}
            filterFlag={filterFlag}
            track={trackEventAction}
            featureFlags={featureFlags}
            setFacetsOptions={setFacetsDataInCollection}
            setFilterOptions={setFilterDataInCategoryBuilder}
            presentation_family={presentation_family}
            brand={categoryData?.brand}
            productFacets={productFacetsBase}
            category={category}
        />
    );

    // use for Filter SVG icon
    const svgIconData = (classFilter, toggleFilterData, selectedFilters = null) => (
        toggleFilterData ? (
            <>
                <span>Hide Filters </span>
                <CloseIcon className={classFilter.hideFilters} />
            </>
        ) : (
            <>
                <span>Filter{selectedFilters ? ` (${selectedFilters})` : ''}</span>
                <FilterIcon />
            </>
        )
    );
    return (
        <Media query={{ maxWidth: 1024 }} defaultMatches={device !== 'desktop'}>
            {(smallScreens) => (smallScreens
                       && (
                           <MobileProductFacetsV3
                               isFoodBrand={isFoodBrand}
                               productFacetsBase={productFacetsBase}
                               isFacetFiltersCountEnabled={isFacetFiltersCountEnabled}
                               category={category}
                               trackEventAction={trackEventAction}
                               categoryData={categoryData}
                               setFacetsDataInCollection={setFacetsDataInCollection}
                               svgIconData={svgIconData}
                               filterDialogData={filterDialogData}
                               abCategorySortAndFilter={abCategorySortAndFilter}
                               presentation_family={presentation_family}
                               setFilterDataInCategoryBuilder={setFilterDataInCategoryBuilder}
                               openDisplayFacets
                               openFacetDropdown={openFacetDropdown}
                               checkedOptions={checkedOptions}
                               setCheckedOptions={setCheckedOptions}
                               resetChecked={resetChecked}
                               productCount={productCount}
                               isFacetFiltersEnabled={isFacetFiltersEnabled}
                               filterOn={filterOn}
                           />
                       ))}
        </Media>
    );
};

MobileDesktopFacetsV3.propTypes = {
    // classes: object.isRequired,
    isFoodBrand: bool.isRequired,
    productFacetsBase: arrayOf(shape({
        name: string,
        entries: arrayOf(shape({
            count: number,
            from: number,
            name: string,
            value: string,
        })),
    })),
    isFacetFiltersCountEnabled: bool.isRequired,
    filterFlag: bool.isRequired,
    category: shape({
        id: string,
        name: string,
        productFacets: arrayOf(shape({
            name: string,
            entries: arrayOf(shape({
                count: number,
                from: number,
                name: string,
                value: string,
            })),
        })),
    }),
    trackEventAction: func,
    categoryData: object.isRequired,
    abCategorySortAndFilter: object.isRequired,
    isFacetFiltersEnabled: bool.isRequired,
    featureFlags: object.isRequired,
    setFacetsDataInCollection: func.isRequired,
    setFilterDataInCategoryBuilder: func.isRequired,
    presentation_family: string.isRequired,
    openFacetDropdown: string.isRequired,
    productCount: number,
    filterOn: string,
};

MobileDesktopFacetsV3.defaultProps = {
    productFacetsBase: [],
    category: {},
    trackEventAction: () => {},
    productCount: 0,
    filterOn: 'Category',
};
const mapStateToProps = (state) => ({
    featureFlags: getFeatureFlags(state),
});
const enhance = compose(
    withRouter,
    connect(mapStateToProps, null),
);

export default enhance(MobileDesktopFacetsV3);
