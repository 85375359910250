/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    arrayOf,
    func,
    number,
    shape, string, array,
} from 'prop-types';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import CloseCircleIcon from '../../../../BrandTheme/Icons/CloseCircleIcon';
import { getFeatureFlag } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';

const useStyles = makeStyles((theme) => ({
    chipsContainer: {
        overflowX: 'auto',
        overflowY: 'hidden',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        gap: '8px',
    },
    modalChipsContainer: {
        padding: '25px 0 30px 0',
        marginRight: '13px',
        marginLeft: '20px',
        [theme.breakpoints.down(415)]: {
            marginLeft: '13px',
        },
    },
    mainViewChipsContainer: {
        padding: '0 0 13px',
    },
    filterChip: {
        display: 'flex',
        backgroundColor: theme.palette.highlightColor || '#F5F5F5',
        padding: '9px 10px 6px 12px',
        borderRadius: '20px',
        fontSize: '14px',
        fontFamily: 'lato, Arial, Verdana, sans-serif',
        whiteSpace: 'nowrap',
        '& svg': {
            margin: '0 0 0 10px',
        },
    },
    chipCloseBtn: {
        border: 'none',
        padding: 0,
        margin: 0,
        backgroundColor: 'transparent',
        lineHeight: 1,
        cursor: 'pointer',
    },
    inline: {
        display: 'inline-block',
        maxWidth: 'calc(100% - 250px)',
    },
    clearAll: {
        marginLeft: '10px',
        textDecoration: 'underline',
        fontSize: 14,
        cursor: 'pointer',
    },
}));

const SelectedFilters = ({
    productFacetsBase, setFacetsDataInCollection, facetsData, history,
}) => {
    const classes = useStyles();
    const isDesktop = useMediaQuery('(min-width: 1025px)');

    // temp flag will be remove once everything working as expected
    const isAggregatorFacetErrorResolver = useSelector(getFeatureFlag('is-facet-throwing-error-resolver-enabled'));

    if (!isDesktop) {
        return <></>;
    }

    const removeFilter = (chipValue) => {
        const newCheckedOptions = facetsData.filter((facetString) => facetString !== chipValue);
        let delimiter = '&';
        if (isAggregatorFacetErrorResolver) {
            delimiter = '&facet=';
        }
        const urlSelectedParams = newCheckedOptions.join(delimiter);
        setTimeout(() => {
            history.replace({
                search: `${urlSelectedParams ? `facet=${urlSelectedParams}` : ''}`,
                state: newCheckedOptions,
            });
        }, 200);
        setFacetsDataInCollection(newCheckedOptions);
    };

    const removeFilterAll = () => {
        setTimeout(() => {
            history.replace({
                search: '',
                state: [],
            });
        }, 200);
        setFacetsDataInCollection([]);
    };

    if (productFacetsBase?.length > 0 && facetsData.length > 0) {
        const checkedFilterNames = [];
        const facetsDataAsArray = Object.values(productFacetsBase);
        if (facetsData) {
            facetsData.forEach((checkedFilter) => {
                facetsDataAsArray.forEach((facet) => {
                    facet.entries.filter((filterOption) => {
                        if (filterOption.value === checkedFilter) {
                            checkedFilterNames.unshift({ name: filterOption.name, value: checkedFilter });
                        }
                    });
                });
            });
        }
        if (checkedFilterNames.length) {
            return (
                <>
                    <div className={classes.inline}>
                        <div className={`${classes.chipsContainer} ${classes.mainViewChipsContainer}`}>
                            {checkedFilterNames.map((filterObj, i) => (
                                <span data-testid={`item-${i}`} className={classes.filterChip}>
                                    {filterObj.name}
                                    <button data-testid={`button-item-${i}`} className={classes.chipCloseBtn} type="button" onClick={() => removeFilter(filterObj.value)}>
                                        <CloseCircleIcon fill="#2F2F2F" />
                                    </button>
                                </span>
                            ))}
                        </div>
                    </div>
                    {checkedFilterNames.length > 1 && (
                        <span
                            role="presentation"
                            onClick={() => removeFilterAll()}
                            className={classes.clearAll}
                            data-testid="button-item-clear-all"
                        >Clear all
                        </span>
                    )}
                </>
            );
        }
    }
    return <></>;
};

SelectedFilters.propTypes = {
    productFacetsBase: arrayOf(shape({
        name: string,
        entries: arrayOf(shape({
            count: number,
            from: number,
            name: string,
            value: string,
        })),
    })),
    facetsData: array,
    setFacetsDataInCollection: func,
    history: shape({
        replace: func,
        push: func,
    }),
};

SelectedFilters.defaultProps = {
    productFacetsBase: [],
    facetsData: [],
    setFacetsDataInCollection: () => {},
    history: {
        replace: () => {},
        push: () => { },
    },
};

export default SelectedFilters;
